import { LoadingButton } from "@mui/lab";
import { Stack, Typography } from "@mui/material";
import axios from "axios";
import { SelectInput } from "components/Inputs";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";

interface FormValues {
  truck: string;
}

interface SelectTruckProps {
  onSubmit: (data: FormValues) => void;
}

const SelectTruck: React.FC<SelectTruckProps> = ({ onSubmit }) => {
  const [trucks, setTrucks] = useState<string[]>([]);
  const { control, handleSubmit, formState } = useForm<FormValues>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const response = await axios.get("/locations/clients");
        setTrucks(
          response.data
            .map((truck: any) => truck.code)
            .filter((code: string) => !code.includes("RETURN"))
        );
      } catch (err) {
        console.error(err);
        toast.error("שגיאה בטעינת המשאיות");
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <Stack spacing={2} p={3}>
      <Typography variant="h5">בחר משאית</Typography>
      <SelectInput
        control={control}
        fieldName="truck"
        label="משאית"
        options={trucks}
        loading={loading}
        rules={{ required: "שדה חובה" }}
      />
      <LoadingButton
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleSubmit(onSubmit)}
        loading={formState.isSubmitting}
      >
        המשך
      </LoadingButton>
    </Stack>
  );
};

export default SelectTruck;

import MainModal from "components/MainModal";

const QrDisplay = ({ open, handleClose }: any) => {
  return (
    <>

      <MainModal
        open={open}
        handleClose={handleClose}
        xBtn
        sx={{ p: 4 }}
      >
        <img
          src="/assets/qr-code.jpeg"
          alt="qr-code"
          width="400"
          height={400}
        />
      </MainModal>
    </>
  );
};

export default QrDisplay;

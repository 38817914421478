import { Box, CircularProgress, Stack } from "@mui/material";
import Logo from "components/logo";
import React from "react";

const LayoutSplashScreen = () => {
  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        height: "100vh",
        width: "100vw",
        zIndex: 99999999999,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Stack
        spacing={2}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: 0.6,
        }}
      >
        <Logo
          sx={{
            height: 0.8,
            width: 1, // Adjust width to be proportional to height
          }}
        />
        <CircularProgress color="error" />
      </Stack>
    </Box>
  );
};

export default LayoutSplashScreen;

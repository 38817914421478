import { Helmet } from "react-helmet-async";

import NewOrderView from "sections/new-order";

// ----------------------------------------------------------------------

export default function LoginPage() {
  return (
    <>
      <Helmet>
        <title> הזמנה ממחסן </title>
      </Helmet>

      <NewOrderView />
    </>
  );
}

// ----------------------------------------------------------------------

import Iconify from "components/iconify";

const navConfig = [
  {
    title: "דף בית",
    path: "/",
    icon: <Iconify icon="mdi:home-analytics" />,
  },
  {
    title: "משתמשים",
    path: "/users",
    icon: <Iconify icon="mdi:user-group" />,
    role: "admin",
  },
  {
    title: "מקררים ומשאיות",
    path: "/locations",
    icon: <Iconify icon="mdi:truck-minus-outline" />,
    role: "admin",
  },
  {
    title: "זיכוי החזרות נהג",
    path: "/credit-return-driver",
    icon: <Iconify icon="mdi:credit-card-refund-outline" />,
    role: "admin",
  },
  {
    title: "הזמנות",
    path: "/orders",
    icon: <Iconify icon="mdi:truck-fast" />,
  },
  {
    title: "תנועת מלאי",
    path: "/inventory/movments",
    icon: <Iconify icon="icon-park-outline:arrow-keys" />,
    role: "admin",
  },
  {
    title: "היסטורית מקררים",
    path: "/machines/log",
    icon: <Iconify icon="material-symbols-light:door-open-outline" />,
    role: "admin",
  },
  {
    title: "היסטורית תקלות",
    path: "/faults-history",
    icon: <Iconify icon="mdi:alert-circle-outline" />,
    role: "admin",
  },
  {
    title: "ניהול נתונים",
    path: "/data-managment",
    icon: <Iconify icon="mdi:database-settings" />,
    role: "admin",
  },
  {
    title: "מלאי במשאית",
    path: "/inventory/truck",
    icon: <Iconify icon="mdi:truck-fast" />,
    role: "driver",
  },
  {
    title: "מלאי החזרות במשאית",
    path: "/inventory/return-truck",
    icon: <Iconify icon="mdi:truck-fast" />,
    role: "driver",
  },
];

export default navConfig;

import { Box } from "@mui/material";
import React from "react";

interface ItemBadgeProps {
  children: number;
}

const ItemBadge: React.FC<ItemBadgeProps> = ({ children }) => {
  const isNegative = children < 0;

  return (
    <Box
      sx={{
        position: "absolute",
        top: "-8px",
        right: "-8px",
        width: "30px",
        height: "30px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: "bold",
        fontSize: "15px",
        bgcolor: isNegative ? "error.main" : "success.light",
        borderRadius: "8px",
        color: "white",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
        border: "1px solid",
      }}
    >
      {children}
    </Box>
  );
};

export default ItemBadge;

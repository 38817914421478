import React from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import InventoryLocationView from "sections/inventory-location";

const MachinePage = () => {
  const { locationId } = useParams();
  return (
    <>
      <Helmet>
        <title> {locationId} </title>
      </Helmet>

      <InventoryLocationView />
    </>
  );
};

export default MachinePage;

import { LoadingButton } from "@mui/lab";
import { Grid2 as Grid } from "@mui/material";
import axios from "axios";
import { TextInput } from "components/Inputs";
import MainModal from "components/MainModal";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { Location } from "./index";
import Iconify from "components/iconify";

interface EditLocationProps {
  location: Location;
  handleClose: () => void;
  updateLocation: (values: Location) => void;
}

const EditLocation: React.FC<EditLocationProps> = ({
  location,
  handleClose,
  updateLocation,
}) => {
  const { control, handleSubmit, formState } = useForm<Location>({
    defaultValues: location,
  });
  const [loading, setLoading] = useState(false);

  const onSubmit = async (values: Location) => {
    try {
      await axios.patch(`/locations/${location.id}`, values);
      toast.success("המיקום עודכן בהצלחה");
      updateLocation(values);
      handleClose();
    } catch (err) {
      console.error(err);
      toast.error("שגיאה בעדכון מיקום");
    }
  };

  const handleLocationUpdate = async () => {
    return new Promise<void>((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            setLoading(true);
            try {
              const { latitude, longitude } = position.coords;
              await axios.post(`locations/${location.id}/gps`, {
                latitude,
                longitude,
              });
              updateLocation({ ...location, latitude, longitude });
              toast.success("המיקום של המקרר עודכן בהצלחה");
            } catch (error) {
              console.error(error);
              toast.error("שגיאה בעדכון מיקום");
            }
            finally {
              setLoading(false);
            }
          },
          (error) => {
            console.error(error);
            toast.error("שגיאה בקבלת מיקום אנא בדוק כי קיימת קליטה והרשאת מיקום");
          }
        );
      } else {
        toast.error("הדפדפן שלך לא תומך בקבלת מיקום");
        reject(new Error("Geolocation not supported"));
      }
    });
  };

  return (
    <MainModal
      title={location.id}
      cancelBtn
      handleClose={handleClose}
      open={Boolean(location)}
      buttons={[
        <LoadingButton
          loading={formState.isSubmitting}
          onClick={handleSubmit(onSubmit)}
        >
          עדכון
        </LoadingButton>,
      ]}
    >
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, lg: 6 }}>
          <TextInput
            fieldName="city"
            control={control}
            label="עיר"
            rules={{
              required: "יש להזין עיר",
              maxLength: {
                value: 50,
                message: "מקסימום 50 תווים",
              },
            }}
          />
        </Grid>
        <Grid size={{ xs: 12, lg: 6 }}>
          <TextInput
            fieldName="address"
            control={control}
            label="כתובת"
            rules={{
              required: "יש להזין כתובת",
              maxLength: {
                value: 255,
                message: "מקסימום 255 תווים",
              },
            }}
          />
        </Grid>
        <Grid size={{ xs: 12, lg: 6 }}>
          <TextInput
            fieldName="internal_location"
            control={control}
            label="מיקום פנימי"
            rules={{
              maxLength: {
                value: 255,
                message: "מקסימום 255 תווים",
              },
            }}
          />
        </Grid>
        <Grid size={{ xs: 12, lg: 6 }}>
          <TextInput
            fieldName="how_to_enter"
            control={control}
            label="דרך כניסה"
            rules={{
              maxLength: {
                value: 255,
                message: "מקסימום 255 תווים",
              },
            }}
          />
        </Grid>
        <Grid size={{ xs: 12, lg: 6 }}>
          <TextInput
            fieldName="contact_name"
            control={control}
            label="איש קשר"
            rules={{
              maxLength: {
                value: 50,
                message: "מקסימום 50 תווים",
              },
            }}
          />
        </Grid>
        <Grid size={{ xs: 12, lg: 6 }}>
          <TextInput
            fieldName="contact_number"
            control={control}
            label="מספר טלפון"
            rules={{
              maxLength: {
                value: 15,
                message: "מקסימום 15 תווים",
              },
            }}
          />
        </Grid>
        <Grid size={{ xs: 12 }}>
          <LoadingButton
            onClick={handleLocationUpdate}
            loading={loading}
            variant="outlined"
            color="success"
            startIcon={<Iconify icon="mdi:location-radius-outline" />}
          >
            עדכון מיקום
          </LoadingButton>
        </Grid>
      </Grid>
    </MainModal>
  );
};

export default EditLocation;

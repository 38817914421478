import { Box, Button, Card, CircularProgress, Typography } from "@mui/material";
import {
    IDetectedBarcode,
    Scanner,
    boundingBox,
} from "@yudiel/react-qr-scanner";
import { useAuth } from "auth";
import axios from "axios";
import MainModal from "components/MainModal";
import {
    MediaPermissionsError,
    requestVideoPermissions,
} from "mic-check/lib/requestMediaPermissions";
import { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const HomeView = () => {
    const { currentUser } = useAuth();
    const [open, setOpen] = useState(false);
    const [updateStcok, setUpdateStock] = useState(false);
    const [paused, setPaused] = useState(false);

    const navigate = useNavigate();

    const onScan = async (result: IDetectedBarcode[]) => {
        if (result.length === 0 || result[0].format === "unknown") return;
        console.log(result[0].rawValue);
        setPaused(true);
        try {
            await axios.get(`/machines/${result[0].rawValue}/planogram`);
            setTimeout(
                () =>
                    navigate(`/machine/${result[0].rawValue}${updateStcok ? "/update" : ''}`),
                1000
            );
        } catch (error) {
            console.error(error);
            toast.error("שגיאה בפתיחת המקרר");
            setPaused(false);
        }
    };

    const handleOpen = (update?: boolean) => {
        return () =>
            requestVideoPermissions()
                .then(() => {
                    setTimeout(() => setOpen(true), 0);
                    setUpdateStock(update ?? false);
                })
                .catch((err: MediaPermissionsError) => {
                    if (err.name === "NotFoundError") {
                        toast.error("מצלמה לא נמצאה");
                    }
                    if (err.name === "NotAllowedError") {
                        toast.error("יש לאפשר הרשאה למצלמה");
                    }
                });
    };

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                bgcolor: "paper",
                height: "100%",
            }}
        >
            <Card sx={{ p: 3 }}>
                <Typography variant="h6">{`שלום ${currentUser?.firstname} ${currentUser?.lastname} 👋`}</Typography>
                <Typography variant="h5" sx={{ mt: 2, mb: 5 }}>
                    לפתיחת מקרר לחץ על הכפתור וסרוק את הברקוד
                </Typography>
                <Button fullWidth variant="contained" onClick={handleOpen()}>
                    פתיחת מקרר
                </Button>
                {currentUser?.role === "admin" && (
                    <Button
                        fullWidth
                        color="secondary"
                        variant="contained"
                        onClick={handleOpen(true)}
                        sx={{ my: 2 }}
                    >
                        פתיחת מקרר לעדכון מלאי
                    </Button>
                )}
                <MainModal
                    open={open}
                    handleClose={() => setOpen(false)}
                    xBtn
                    sx={{ p: 3 }}
                >
                    <Box sx={{ position: "relative", width: 400, height: 400 }}>
                        {paused && (
                            <Box
                                sx={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    zIndex: 1000,
                                }}
                            >
                                <CircularProgress color="primary" />
                            </Box>
                        )}

                        {/* Scanner component */}
                        {open && (
                            <Scanner
                                formats={["qr_code"]}
                                onScan={onScan}
                                components={{
                                    audio: true,
                                    torch: true,
                                    zoom: true,
                                    finder: true,
                                    tracker: boundingBox,
                                }}
                                paused={paused}
                                scanDelay={2000}
                                onError={(error) => {
                                    console.log(`onError: ${error}`);
                                }}
                            />
                        )}
                    </Box>
                </MainModal>
            </Card>
        </Box>
    );
};

export default HomeView;

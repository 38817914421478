export const roles: Record<string, string> = {
  admin: "Admin",
  driver: "נהג",
};

export const locationType: Record<string, string> = {
  TRUCK: "משאית",
  WAREHOUSE: "מחסן",
  MACHINE: "מקרר",
};

export const status: Record<string, string> = {
  PENDING: "ממתין",
  COMPLETED: "הושלם",
  DELETED: "בוטל",
};

export const orderType: Record<string, string> = {
  ORDER: "מילוי מקרר",
  RETURN: "חזרות",

  LOAD: "העמסה",

  CREDIT: "זיכוי",
};
export const movementType: Record<string, string> = {
  ...orderType,
  UPDATE: "ספירת מלאי",
  SELL: "מכירה",
};

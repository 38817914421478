import axios from "axios";
import { AuthModel, UserModel } from "./_models";

export const GET_USER_BY_ACCESSTOKEN_URL = `auth/get-user`;
export const LOGIN_URL = `auth/login`;
export const GET_REFRESH_TOKEN_URL = `auth/refresh-token`;

// Server should return AuthModel
export function login(username: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    username,
    password,
  });
}

export function getUserByToken() {
  return axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_URL);
}

export function getRefreshToken(prevAccessToken: string, refreshToken: string) {
  return axios.post<AuthModel>(
    GET_REFRESH_TOKEN_URL,
    {
      accessToken: prevAccessToken,
      refreshToken: refreshToken,
    },
    {
      headers: {
        Authorization: "Bearer " + refreshToken,
      },
    }
  );
}

import { LoadingButton } from "@mui/lab";
import { Button, Stack } from "@mui/material";
import axios from "axios";
import ItemAmount from "components/itemAmout";
import ItemBadge from "components/ItemBadge";
import Planogram, { Cell } from "components/planogram";
import { useConfirm } from "material-ui-confirm";
import React, { useLayoutEffect, useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";

interface ReturnOrderProps {
  cells: Cell[];
  nextStep: () => void;
  log_id: number;
}

const ReturnOrder: React.FC<ReturnOrderProps> = ({
  cells,
  nextStep,
  log_id,
}) => {
  const { id } = useParams();
  const [items, setItems] = useState<Cell[]>([]);
  const [selectedItem, setSelectedItem] = useState<Cell>();
  const confirm = useConfirm();
  const [loading, setLoading] = useState(false);

  useLayoutEffect(() => {
    setItems(cells.map((cell) => ({ ...cell, amount: 0 })));
  }, [cells]);

  const handleSkip = () => {
    confirm({ title: "האם אתה בטוח שברצונך לדלג על החזרת המוצרים?" })
      .then(() => {
        const item = localStorage.getItem("machine-process");
        if (item) {
          const { activeStep, log_id, timestamp } = JSON.parse(item);
          localStorage.setItem(
            "machine-process",
            JSON.stringify({ log_id, activeStep: activeStep + 1, timestamp, machine_id: id })
          );
        }
        nextStep();
      })
      .catch(() => { });
  };

  const handleSubmit = () => {
    confirm({ title: "האם אתה בטוח שברצונך להחזיר את המוצרים?" })
      .then(async () => {
        setLoading(true);
        try {
          await axios.post(
            `/machines/${log_id}/return`,
            items
              .filter((item) => item.amount > 0)
              .map((item) => ({ sku: item.sku, amount: item.amount }))
          );
          const item = localStorage.getItem("machine-process");
          if (item) {
            const { activeStep, log_id, timestamp } = JSON.parse(item);
            localStorage.setItem(
              "machine-process",
              JSON.stringify({ log_id, activeStep: activeStep + 1, timestamp, machine_id: id })
            );
          }
          nextStep();
          toast.success("החזרה בוצעה בהצלחה");
        } catch (error) {
          console.error(error);
          toast.error("שגיאה בביצוע ההחזרה");
        } finally {
          setLoading(false);
        }
      })
      .catch(() => { });
  };

  return (
    <>
      <Stack spacing={1}>
        <Planogram
          cells={items}
          onCellClick={(cell) => setSelectedItem(cell)}
          getBadge={(cell) =>
            cell.amount > 0 ? <ItemBadge>{cell.amount}</ItemBadge> : null
          }
        />
      </Stack>
      {selectedItem && (
        <ItemAmount
          item={selectedItem}
          handleClose={() => setSelectedItem(undefined)}
          helperText="כמות ביחידות !"
        />
      )}
      <Stack direction="row" spacing={2} sx={{ my: 2, px: 3 }}>
        <Button
          variant="contained"
          color="secondary"
          fullWidth
          onClick={handleSkip}
        >
          דלג
        </Button>
        <LoadingButton
          variant="contained"
          fullWidth
          onClick={handleSubmit}
          loading={loading}
          disabled={items.every((item) => item.amount === 0)}
        >
          שלב הבא
        </LoadingButton>
      </Stack>
    </>
  );
};

export default ReturnOrder;

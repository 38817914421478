import { Card, Container, Stack, Typography } from "@mui/material";
import { useResponsive } from "hooks/use-responsive";
import { WithChildren } from "utils/types";

interface ContainerWrapperProps extends WithChildren {
  title?: string;
  secondaryAction?: React.ReactNode;
}

const ContainerWrapper: React.FC<ContainerWrapperProps> = ({
  children,
  title,
  secondaryAction,
}) => {
  const lgUp = useResponsive("up", "md");

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        maxWidth: "100% !important",
        m: 0,
        px: 2,
        my: 1,
        ...(lgUp && {
          px: 3,
          py: 2,
        }),
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
        px={2}
      >
        <Typography variant="h4">{title}</Typography>
        {secondaryAction}
      </Stack>
      <Card
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          width: "100%",
          py: 1
        }}
      >
        <div style={{ flexGrow: 1 }}>{children}</div>
      </Card>
    </Container>
  );
};

export default ContainerWrapper;

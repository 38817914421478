import { LoadingButton } from "@mui/lab";
import { Stack, Typography } from "@mui/material";
import { useAuth } from "auth";
import axios from "axios";
import ContianerWapper from "components/ContainerWrapper";
import ItemBadge from "components/ItemBadge";
import Planogram, { Cell } from "components/planogram";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { Item } from "utils/types";
import ItemAmount from "components/itemAmout";
import SelectClient from "./SelectClient";
import { useNavigate } from "react-router-dom";

interface FormValues {
  client: string;
}

const NewOrderView = () => {
  const { currentUser } = useAuth();
  const [items, setItems] = useState<Cell[]>([]);
  const [selectedItem, setSelectedItem] = useState<Cell>();
  const navigate = useNavigate();
  const methods = useForm<FormValues>({
    defaultValues: {
      client: currentUser?.location_id,
    },
  });

  useEffect(() => {
    (async () => {
      try {
        const response = await axios("/items/");
        setItems(
          response.data.map((item: Item) => ({
            ...item,
            cols: 4,
          }))
        );
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  const onSubmit = async (data: FormValues) => {
    if (!items.some((item) => item.amount > 0)) {
      toast.error("לא נבחרו פריטים להזמנה");
      return;
    }
    const order: Record<string, number> = {};
    items.forEach((item) => {
      if (item.amount > 0) {
        order[item.sku] = item.amount;
      }
    });
    try {
      const reponse = await axios.post("/orders/", {
        client: data.client,
        order,
      });
      console.log(reponse.data);
      toast.success("הזמנה נשלחה בהצלחה");
      navigate("/orders");
    } catch (error) {
      console.error(error);
      toast.error("שגיאה בשליחת הזמנה");
    }
  };

  return (
    <ContianerWapper
      title="הזמנה ממחסן"
      secondaryAction={
        <LoadingButton
          onClick={methods.handleSubmit(onSubmit)}
          loading={methods.formState.isSubmitting}
          variant="contained"
        >
          ביצוע הזמנה
        </LoadingButton>
      }
    >
      <FormProvider {...methods}>
        <Stack spacing={2}>
          {!currentUser?.location_id && <SelectClient />}
          <Typography variant="h6" px={3}>
            הוסף פריטים
          </Typography>
          <Planogram
            cells={items}
            onCellClick={(cell) => setSelectedItem(cell)}
            getBadge={(cell) =>
              cell.amount > 0 ? <ItemBadge>{cell.amount}</ItemBadge> : null
            }
          />
        </Stack>
      </FormProvider>
      {selectedItem && (
        <ItemAmount
          item={selectedItem}
          handleClose={() => setSelectedItem(undefined)}
          helperText={`כמות יחידות בקרטון:  ${selectedItem.unit}`}
        />
      )}
    </ContianerWapper>
  );
};

export default NewOrderView;

import React from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import MachineView from "sections/machine";

const MachinePage = () => {
  const { id } = useParams();
  return (
    <>
      <Helmet>
        <title> {`מכונה ${id}`} </title>
      </Helmet>

      <MachineView />
    </>
  );
};

export default MachinePage;

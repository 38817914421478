import { LoadingButton } from '@mui/lab'
import { Stack } from '@mui/material'
import axios from 'axios'
import Iconify from 'components/iconify'
import { FileInput, TextInput } from 'components/Inputs'
import MainModal from 'components/MainModal'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'

interface FaultLocationProps {
    locationId?: string;
    setLocationId: any
}

interface FormValues {
    description: string
    image: File
}

const FaultLocation: React.FC<FaultLocationProps> = ({ locationId, setLocationId }) => {
    const [open, setOpen] = useState(false)
    const { control, handleSubmit, reset, formState } = useForm<FormValues>()
    useEffect(() => {
        if (locationId) {
            setOpen(true)
        } else {
            setOpen(false)
        }
    }, [locationId])

    const close = () => {
        setLocationId(undefined)
        reset()
    }

    const onSubmit = async (data: FormValues) => {
        const formData = new FormData();
        formData.append("image", data.image);
        formData.append("description", data.description);
        try {
            await axios.post(`/locations/${locationId}/fault`, formData);
            toast.success("דיווח תקלה נשמר בהצלחה");
            close();
        } catch (error) {
            console.error("Error:", error);
            toast.error("דיווח תקלה נכשל");
        }
    }

    return (
        <MainModal open={open} handleClose={close} title={`דיווח תקלה ${locationId}`} xBtn>
            <Stack spacing={2}>
                <TextInput control={control} fieldName="description" label="תיאור התקלה" multiline={5} rules={{ required: "שדה חובה" }} />
                <FileInput control={control} fieldName="image" placeholder="תמונה" icon={<Iconify icon="mdi:camera-outline" />}
                    accept="image/*" capture='environment'
                    rules={{ required: "שדה חובה" }}
                />

                <LoadingButton loading={formState.isSubmitting} onClick={handleSubmit(onSubmit)} variant="contained">
                    שמור דיווח
                </LoadingButton>
            </Stack>
        </MainModal>
    )
}

export default FaultLocation
import { LoadingButton } from "@mui/lab";
import { Box, Button, Fab, Stack } from "@mui/material";
import Iconify from "components/iconify";
import React, { useRef, useState } from "react";
import { Camera, CameraType } from "react-camera-pro";
import toast from "react-hot-toast";

interface CapturePhotoProps {
  handlePhoto: (photo: string) => Promise<void>;
  nextStep: () => void;
}

const CapturePhoto: React.FC<CapturePhotoProps> = ({
  handlePhoto,
  nextStep,
}) => {
  const camera = useRef<CameraType>(null);
  const [showBtn, setShowBtn] = useState<boolean>();
  const [image, setImage] = useState<string>();
  const [loading, setLoading] = useState(false);

  const handleSave = async () => {
    if (!image) return;
    setLoading(true);
    try {
      await handlePhoto(image);
    } catch (error) {
      console.error(error);
      toast.error("שגיאה בשמירת התמונה");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        mx: 5,
        display: "flex",
        flexDirection: "column",
        p: 1,
      }}
    >
      <Box
        sx={{
          border: "2px solid",
          borderRadius: "4px",
          position: "relative",
          width: "100%",
          height: "62vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {image && <img src={image} alt="captured" width="100%" height="100%" />}
        {!image && (
          <>
            <Camera
              ref={camera}
              facingMode="environment"
              errorMessages={{
                noCameraAccessible:
                  "אין גישה למצלמה. אנא חבר מצלמה או נסה דפדפן אחר.",
                permissionDenied:
                  "הגישה למצלמה נדחתה. אנא רענן את הדף ותן הרשאה למצלמה.",
                switchCamera:
                  "לא ניתן להחליף למצלמה אחרת כי יש רק מכשיר וידאו אחד זמין.",
                canvas: "ה-Canvas לא נתמך.",
              }}
              videoReadyCallback={() => setShowBtn(true)}
            />
            {showBtn && (
              <Fab
                sx={{
                  position: "absolute",
                  bottom: 45,
                  left: "50%",
                  bgcolor: "transparent",
                  transform: "translateX(-50%)",
                }}
                onClick={() => {
                  if (camera.current) {
                    try {
                      const photo = camera.current.takePhoto();
                      setImage(photo as string);
                    } catch (error) {
                      console.error(error);
                      toast.error("שגיאה בצילום התמונה");
                    }
                  }
                }}
              >
                <Iconify
                  icon="carbon:circle-filled"
                  sx={{
                    height: 1,
                    width: 1,
                    color: "#F4F6FF",
                    "&:hover": {
                      color: "#f0f0f0",
                    },
                  }}
                />
              </Fab>
            )}
          </>
        )}
      </Box>
      {image && (
        <Stack direction="row" spacing={2} sx={{ mt: 2, mb: 1 }}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setImage(undefined)}
            fullWidth
          >
            צלם שנית
          </Button>
          <LoadingButton
            variant="contained"
            onClick={handleSave}
            fullWidth
            loading={loading}
          >
            שמור
          </LoadingButton>
        </Stack>
      )}
    </Box>
  );
};

export default CapturePhoto;

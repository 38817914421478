import { Box, Drawer, IconButton } from "@mui/material";
import {
  GridColumnsManagement,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarQuickFilter,
  useGridApiContext,
} from "@mui/x-data-grid";
import Iconify from "components/iconify";
import { useState } from "react";

export function ManageColumns() {
  const api = useGridApiContext();
  const [open, setOpen] = useState(true);
  const handleClose = () => {
    setOpen(false);
    setTimeout(() => api.current.hidePreferences(), 0);
  };
  return (
    <Drawer open={open} onClose={handleClose} anchor="right">
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          p: 1,
        }}
      >
        <IconButton onClick={handleClose}>
          <Iconify icon="bi:arrow-right" />
        </IconButton>
      </Box>
      <GridColumnsManagement />
    </Drawer>
  );
}

export const ToolBar = () => {
  return (
    <GridToolbarContainer sx={{ p: 2 }}>
      <GridToolbarQuickFilter variant="outlined" size="small" />
      <Box sx={{ flexGrow: 1 }} />
      <GridToolbarColumnsButton />
      <GridToolbarExport
        csvOptions={{
          utf8WithBom: true,
        }}
      />
    </GridToolbarContainer>
  );
};

export const hebrewText = {
  MuiTablePagination: {
    labelDisplayedRows: ({ from, to, count }: any) =>
      `${from}–${to} מתוך ${count !== -1 ? count : `יותר מ-${to}`}`,
    labelRowsPerPage: "שורות לדף",
  },
  // Root
  noRowsLabel: "אין שורות",
  noResultsOverlayLabel: "לא נמצאו תוצאות",

  // Density selector toolbar button text
  toolbarDensity: "צפיפות",
  toolbarDensityLabel: "צפיפות",
  toolbarDensityCompact: "צפוף",
  toolbarDensityStandard: "סטנדרטי",
  toolbarDensityComfortable: "נוח",

  // Columns selector toolbar button text
  toolbarColumns: "עמודות",
  toolbarColumnsLabel: "בחר עמודות",

  // Filters toolbar button text
  toolbarFilters: "מסננים",
  toolbarFiltersLabel: "הצג מסננים",
  toolbarFiltersTooltipHide: "הסתר מסננים",
  toolbarFiltersTooltipShow: "הצג מסננים",
  toolbarFiltersTooltipActive: (count: number) =>
    count !== 1 ? `${count} מסננים פעילים` : `${count} מסנן פעיל`,

  // Quick filter toolbar field
  toolbarQuickFilterPlaceholder: "חפש...",
  toolbarQuickFilterLabel: "חיפוש",
  toolbarQuickFilterDeleteIconLabel: "נקה",

  // Export selector toolbar button text
  toolbarExport: "ייצוא",
  toolbarExportLabel: "ייצוא",
  toolbarExportCSV: "הורד כ-CSV",
  toolbarExportPrint: "הדפס",
  toolbarExportExcel: "הורד כ-Excel",

  // Columns management text
  columnsManagementSearchTitle: "חיפוש",
  columnsManagementNoColumns: "אין עמודות",
  columnsManagementShowHideAllText: "הצג/הסתר הכל",
  columnsManagementReset: "איפוס",

  // Filter panel text
  filterPanelAddFilter: "הוסף מסנן",
  filterPanelRemoveAll: "הסר הכל",
  filterPanelDeleteIconLabel: "מחק",
  filterPanelLogicOperator: "אופרטור לוגי",
  filterPanelOperator: "אופרטור",
  filterPanelOperatorAnd: "וגם",
  filterPanelOperatorOr: "או",
  filterPanelColumns: "עמודות",
  filterPanelInputLabel: "ערך",
  filterPanelInputPlaceholder: "ערך מסנן",

  // Filter operators text
  filterOperatorContains: "מכיל",
  filterOperatorDoesNotContain: "לא מכיל",
  filterOperatorEquals: "שווה ל-",
  filterOperatorDoesNotEqual: "לא שווה ל-",
  filterOperatorStartsWith: "מתחיל ב-",
  filterOperatorEndsWith: "מסתיים ב-",
  filterOperatorIs: "הוא",
  filterOperatorNot: "אינו",
  filterOperatorAfter: "אחרי",
  filterOperatorOnOrAfter: "ב או אחרי",
  filterOperatorBefore: "לפני",
  filterOperatorOnOrBefore: "ב או לפני",
  filterOperatorIsEmpty: "ריק",
  filterOperatorIsNotEmpty: "לא ריק",
  filterOperatorIsAnyOf: "הוא אחד מ-",
  "filterOperator=": "=",
  "filterOperator!=": "!=",
  "filterOperator>": ">",
  "filterOperator>=": ">=",
  "filterOperator<": "<",
  "filterOperator<=": "<=",

  // Header filter operators text
  headerFilterOperatorContains: "מכיל",
  headerFilterOperatorDoesNotContain: "לא מכיל",
  headerFilterOperatorEquals: "שווה ל-",
  headerFilterOperatorDoesNotEqual: "לא שווה ל-",
  headerFilterOperatorStartsWith: "מתחיל ב-",
  headerFilterOperatorEndsWith: "מסתיים ב-",
  headerFilterOperatorIs: "הוא",
  headerFilterOperatorNot: "אינו",
  headerFilterOperatorAfter: "אחרי",
  headerFilterOperatorOnOrAfter: "ב או אחרי",
  headerFilterOperatorBefore: "לפני",
  headerFilterOperatorOnOrBefore: "ב או לפני",
  headerFilterOperatorIsEmpty: "ריק",
  headerFilterOperatorIsNotEmpty: "לא ריק",
  headerFilterOperatorIsAnyOf: "הוא אחד מ-",
  "headerFilterOperator=": "שווה ל-",
  "headerFilterOperator!=": "לא שווה ל-",
  "headerFilterOperator>": "גדול מ-",
  "headerFilterOperator>=": "גדול או שווה ל-",
  "headerFilterOperator<": "קטן מ-",
  "headerFilterOperator<=": "קטן או שווה ל-",

  // Filter values text
  filterValueAny: "כלשהו",
  filterValueTrue: "כן",
  filterValueFalse: "לא",

  // Column menu text
  columnMenuLabel: "תפריט",
  columnMenuShowColumns: "הצג עמודות",
  columnMenuManageColumns: "נהל עמודות",
  columnMenuFilter: "סנן",
  columnMenuHideColumn: "הסתר עמודה",
  columnMenuUnsort: "בטל מיון",
  columnMenuSortAsc: "מיין לפי סדר עולה",
  columnMenuSortDesc: "מיין לפי סדר יורד",

  // Column header text
  columnHeaderFiltersTooltipActive: (count: number) =>
    count !== 1 ? `${count} מסננים פעילים` : `${count} מסנן פעיל`,
  columnHeaderFiltersLabel: "הצג מסננים",
  columnHeaderSortIconLabel: "מיין",

  // Rows selected footer text
  footerRowSelected: (count: number) =>
    count !== 1
      ? `${count.toLocaleString()} שורות נבחרו`
      : `${count.toLocaleString()} שורה נבחרה`,

  // Total row amount footer text
  footerTotalRows: 'סה"כ שורות:',

  // Total visible row amount footer text
  footerTotalVisibleRows: (visibleCount: number, totalCount: number) =>
    `${visibleCount.toLocaleString()} מתוך ${totalCount.toLocaleString()}`,

  // Checkbox selection text
  checkboxSelectionHeaderName: "בחירת תיבה",
  checkboxSelectionSelectAllRows: "בחר את כל השורות",
  checkboxSelectionUnselectAllRows: "בטל את בחירת כל השורות",
  checkboxSelectionSelectRow: "בחר שורה",
  checkboxSelectionUnselectRow: "בטל את בחירת השורה",

  // Boolean cell text
  booleanCellTrueLabel: "כן",
  booleanCellFalseLabel: "לא",

  // Actions cell more text
  actionsCellMore: "עוד",

  // Column pinning text
  pinToLeft: "נעץ בצד שמאל",
  pinToRight: "נעץ בצד ימין",
  unpin: "הסר נעיצה",

  // Tree Data
  treeDataGroupingHeaderName: "קבוצה",
  treeDataExpand: "הצג ילדים",
  treeDataCollapse: "הסתר ילדים",

  // Grouping columns
  groupingColumnHeaderName: "קבוצה",
  groupColumn: (name: string) => `קבץ לפי ${name}`,
  unGroupColumn: (name: string) => `הפסק לקבץ לפי ${name}`,

  // Master/detail
  detailPanelToggle: "החלף פאנל פרטים",
  expandDetailPanel: "הרחב",
  collapseDetailPanel: "צמצם",

  // Row reordering text
  rowReorderingHeaderName: "סידור שורות מחדש",

  // Aggregation
  aggregationMenuItemHeader: "אגרגציה",
  aggregationFunctionLabelSum: "סכום",
  aggregationFunctionLabelAvg: "ממוצע",
  aggregationFunctionLabelMin: "מינימום",
  aggregationFunctionLabelMax: "מקסימום",
  aggregationFunctionLabelSize: "גודל",
};

import React, { useEffect, useRef, useState, useCallback, ChangeEvent } from "react";
import { Box, IconButton, Stack, TextField, Typography, Button } from "@mui/material";
import Iconify from "../components/iconify";
import MainModal from "../components/MainModal";
import { Cell } from "../components/planogram";

interface ItemAmountProps {
  item: Cell;
  handleClose: () => void;
  helperText?: string;
}

const ItemAmount: React.FC<ItemAmountProps> = ({
  item,
  handleClose,
  helperText,
}) => {
  const [amount, setAmount] = useState<number>(item.amount ?? 0);
  const [error, setError] = useState<string | undefined>();
  const inputRef = useRef<HTMLInputElement>(null);

  const onClose = () => {
    if (amount === undefined || amount < 0) {
      setError("אנא הכנס כמות חוקית");
      return;
    }
    handleClose();
    item.amount = amount;
  };

  const focusInput = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (item) {
      // Use setTimeout to ensure the modal is fully rendered before focusing
      setTimeout(focusInput, 100);
    }
  }, [item, focusInput]);

  const handleAmountChange = (e: ChangeEvent<HTMLInputElement>) => {
    setAmount(parseInt(e.target.value) || 0);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      onClose()
    }
  };

  return (
    <MainModal
      open={Boolean(item)}
      handleClose={onClose}
      title={item.name}
      sx={{ minWidth: 270, minHeight: 300, top: '-15%', }}
    >
      {helperText && <Typography variant="subtitle1">{helperText}</Typography>}
      <Box
        sx={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: 'column'
        }}
      >
        <Stack direction="row" spacing={2} sx={{ mt: 5 }}>
          <IconButton onClick={() => setAmount(amount + 1)} color="primary">
            <Iconify icon="mdi:plus-thick" />
          </IconButton>
          <TextField
            value={amount}
            inputRef={inputRef}
            onChange={handleAmountChange}
            variant="outlined"
            sx={{ width: 100 }}
            slotProps={{
              htmlInput: {
                inputMode: "numeric",
                pattern: "[0-9]*",
                min: 0,
              }
            }}
            error={Boolean(error)}
            helperText={error}
            onKeyDown={handleKeyDown}
          />
          <IconButton
            onClick={() => setAmount(Math.max(0, amount - 1))}
            disabled={amount === 0}
            color="primary"
          >
            <Iconify icon="mdi:minus-thick" />
          </IconButton>
        </Stack>
        <Button onClick={onClose} sx={{ mt: 2 }}>שמירה</Button>
      </Box>
    </MainModal>
  );
};

export default ItemAmount;

import { Helmet } from "react-helmet-async";
import HomeView from "sections/home";

// ----------------------------------------------------------------------

export default function LoginPage() {
  return (
    <>
      <Helmet>
        <title> דף בית </title>
      </Helmet>

      <HomeView />
    </>
  );
}

import { lazy, useMemo } from "react";
import { Outlet, Navigate, useRoutes, useLocation } from "react-router-dom";
import Page404 from "pages/page-not-found";
import IndexPage from "pages/index";
import LoginPage from "pages/login";
import MachinePage from "pages/machine";
import NewOrderPage from "pages/new-order";
import OrdersPage from "pages/orders";
import InventoryLocationPage from "pages/inventory-location";
import DashboardLayout from "layouts";
import { useAuth } from "auth";

export const UsersPage = lazy(() => import("pages/users"));
export const LocationsPage = lazy(() => import("pages/locations"));
export const InventoryMovmentsPage = lazy(() =>
  import("pages/inventory-movments")
);
export const MachineLogPage = lazy(() => import("pages/machine-log"));
export const DataManagmentPage = lazy(() => import("pages/data-managment"));
export const MachineUpdatePage = lazy(() => import("pages/machine-update"));
export const FaultsHistoryPage = lazy(() => import("pages/fault-history"));
export const MachineCreditPage = lazy(() => import("pages/machine-credit"));

// ----------------------------------------------------------------------
const UnauthorizedErrorHandler = () => {
  const { pathname } = useLocation();
  return (
    <Navigate
      to={"/auth/login"}
      state={{
        redirectTo: pathname,
      }}
    />
  );
};

const LoginRedirectionHandler = () => {
  const { state } = useLocation();

  if (state && state.redirectTo) {
    window.history.pushState(null, "", "/");
    return <Navigate to={state.redirectTo} />;
  }

  return <Navigate to={"/"} />;
};

export default function Router() {
  const { currentUser } = useAuth();

  const allowedRoutes = useMemo(() => {
    const routes = [
      { path: "users", element: <UsersPage />, role: "admin" },
      {
        path: "locations",
        element: <LocationsPage />,
        role: "admin",
      },
      {
        path: "orders/new",
        element: <NewOrderPage />,
      },
      {
        path: "orders",
        element: <OrdersPage />,
      },
      {
        path: "machine/:id",
        element: <MachinePage />,
      },
      {
        path: "inventory/movments",
        element: <InventoryMovmentsPage />,
        role: "admin",
      },
      {
        path: "machines/log",
        element: <MachineLogPage />,
        role: "admin",
      },
      {
        path: "data-managment",
        element: <DataManagmentPage />,
        role: "admin",
      },
      {
        path: "inventory/:locationId",
        element: <InventoryLocationPage />,
      },
      {
        path: "inventory/truck",
        element: <Navigate to={`/inventory/${currentUser?.location_id}`} />,
        role: "driver",
      },
      {
        path: "inventory/return-truck",
        element: (
          <Navigate to={`/inventory/${currentUser?.location_id}_RETURN`} />
        ),
        role: "driver",
      },
      {
        path: "machine/:id/update",
        element: <MachineUpdatePage />,
        role: "admin",
      },
      {
        path: "faults-history",
        element: <FaultsHistoryPage />,
        role: "admin",
      },
      {
        path: "credit-return-driver",
        element: <MachineCreditPage />,
        role: "admin",
      },
    ];
    return routes.filter((route) =>
      route.role ? currentUser?.role === route.role : true
    );
  }, [currentUser]);

  const routes = useRoutes([
    {
      element: currentUser ? (
        <DashboardLayout>
          <Outlet />
        </DashboardLayout>
      ) : (
        <UnauthorizedErrorHandler />
      ),
      children: [...allowedRoutes, { element: <IndexPage />, index: true }],
    },
    {
      path: "auth",
      element: !currentUser ? <Outlet /> : <LoginRedirectionHandler />,
      children: [
        { element: <Navigate to="/auth/login" />, index: true },
        { path: "login", element: <LoginPage /> },
      ],
    },
    {
      path: "404",
      element: <Page404 />,
    },
    {
      path: "*",
      element: currentUser ? (
        <Navigate to="/404" replace />
      ) : (
        <UnauthorizedErrorHandler />
      ),
    },
  ]);

  return routes;
}

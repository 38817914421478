import { Helmet } from "react-helmet-async";

import { NotFoundView } from "sections/error";

// ----------------------------------------------------------------------

export default function NotFoundPage() {
  return (
    <>
      <Helmet>
        <title> 404 דף לא נמצא </title>
      </Helmet>

      <NotFoundView />
    </>
  );
}

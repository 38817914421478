import { Button, Menu, MenuItem } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import Iconify from './iconify';
import QrDisplay from 'sections/machine/QrDisplay';
import FaultLocation from 'sections/locations/fault-location';
import EditLocation from 'sections/locations/edit-location';
import { Location } from "sections/locations";

const MachineActions = ({ locationId }: { locationId: string }) => {
    const [location, setLocation] = useState<Location>();
    const [saveLocation, setSaveLocation] = useState<Location>();
    const [faultLocation, setFaultLocation] = useState<string>();
    const [openQr, setOpenQr] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    useEffect(() => {
        (async () => {
            try {
                const res = await axios(`/locations/${locationId}`);
                setSaveLocation(res.data);
            } catch (error) {
                console.error(error);
                toast.error("שגיאה בטעינת המכונה");
            }
        })();
    }, [locationId]);

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };
    return (
        <>
            <Button
                onClick={handleMenuOpen}
                startIcon={<Iconify icon="mdi:menu" />}
                disabled={!saveLocation}
            >
                פעולות
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={() => {
                    handleMenuClose();
                    setOpenQr(true);
                }}>
                    <Iconify icon="mdi:qrcode" sx={{ mr: 2 }} />
                    הצג QR
                </MenuItem>
                <MenuItem onClick={() => {
                    handleMenuClose();
                    setLocation(saveLocation);
                }}>
                    <Iconify icon="mdi:pencil" sx={{ mr: 2 }} />
                    עריכת מיקום
                </MenuItem>
                <MenuItem onClick={() => {
                    handleMenuClose();
                    setFaultLocation(locationId);
                }}>
                    <Iconify icon="mdi:error-outline" sx={{ mr: 2 }} />
                    דיווח תקלה
                </MenuItem>
            </Menu>
            <FaultLocation locationId={faultLocation} setLocationId={setFaultLocation} />
            <QrDisplay open={openQr} handleClose={() => setOpenQr(false)} />
            {location && (
                <EditLocation
                    updateLocation={(newLocation: Location) =>
                        setSaveLocation(newLocation)
                    }
                    handleClose={() => setLocation(undefined)}
                    location={location}
                />
            )}
        </>
    )
}

export default MachineActions
import { Button } from "@mui/material";
import axios from "axios";
import ContianerWapper from "components/ContainerWrapper";
import DataTable from "components/DataTable";
import Iconify from "components/iconify";
import { MRT_ColumnDef } from "material-react-table";
import moment from "moment-timezone";
import { useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { RouterLink } from "routes/components";
import { orderType, status } from "utils/hebrew";

interface Order {
  id: string;
  order_date: Date;
  itemCount: number;
  status: string;
  from_location: string;
  to_location: string;
}

const OrdersView = () => {
  const [orders, setOrders] = useState<Order[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const resposne = await axios.get("/orders/");
        resposne.data.forEach(
          (order: Order) => (order.order_date = new Date(order.order_date))
        );
        setOrders(resposne.data);
      } catch (e) {
        console.error(e);
        toast.error("שגיאה בטעינת ההזמנות");
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const columns = useMemo<MRT_ColumnDef<any, unknown>[]>(
    () => [
      { accessorKey: "id", header: "מספר הזמנה" },
      {
        accessorFn: (row) => orderType[row.type],
        header: "סוג",
        filterVariant: "autocomplete",
        filterSelectOptions: Object.values(orderType),
      },
      {
        accessorFn: (row) => moment(row.order_date).format("DD/MM/YYYY HH:mm"),
        header: "תאריך הזמנה",
        filterVariant: "datetime-range",
      },
      { accessorKey: "itemCount", header: "מספר פריטים" },
      {
        accessorFn: (row) => status[row.status],
        header: "סטטוס הזמנה",
        filterVariant: "autocomplete",
        filterSelectOptions: Object.values(status),
      },
      { accessorKey: "from_location", header: "ממקום" },
      { accessorKey: "to_location", header: "למקום" },
    ],
    []
  );

  return (
    <ContianerWapper
      title="הזמנות"
      secondaryAction={
        <Button
          LinkComponent={RouterLink}
          href="/orders/new"
          variant="contained"
          color="success"
          startIcon={<Iconify icon="mdi:plus-thick" />}
        >
          הזמנה חדשה
        </Button>
      }
    >
      <DataTable data={orders} loading={loading} columns={columns} />
    </ContianerWapper>
  );
};

export default OrdersView;

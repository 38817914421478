import { Box, Grid2 as Grid, Skeleton } from "@mui/material";
import React from "react";
import { Item } from "utils/types";

export interface Cell extends Item {
  cols: number;
  [key: string]: any;
}

interface PlanogramProps {
  cells: Cell[];
  onCellClick?: (cell: Cell) => void;
  getDisabled?: (cell: Cell) => boolean;
  getBadge?: (cell: Cell) => React.ReactNode;
}

const Planogram: React.FC<PlanogramProps> = ({
  cells,
  onCellClick,
  getDisabled,
  getBadge,
}) => {
  return (
    <Grid container spacing={1} columns={20} px={3} sx={{ height: "63vh" }}>
      {cells.length === 0 &&
        Array.from({ length: 20 }).map((_, index) => (
          <Grid size={5} key={index}>
            <Skeleton variant="rounded" height="15vh" />
          </Grid>
        ))}
      {cells.length > 0 &&
        cells.map((cell) => (
          <Grid
            size={cell.cols}
            key={cell.sku}
            sx={{
              border: "1px solid",
              borderColor: "success.main",
              borderRadius: "8px",
              ...(onCellClick && {
                cursor: "pointer",
                transition: "all 0.3s ease",
                "&:hover": {
                  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                  transform: "scale(1.02)",
                },
              }),
            }}
            onClick={onCellClick ? () => onCellClick(cell) : undefined}
            component="button"
            disabled={getDisabled ? getDisabled(cell) : false}
          >
            <Box
              sx={{
                height: "100%",
                p: 1,
                background: `url(${cell.picture ?? '/assets/error-photo.jpg'})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
                position: "relative",
              }}
            >
              {getBadge && getBadge(cell)}
              <Box
                sx={{
                  position: "absolute",
                  bottom: 0,
                  width: "75%",
                  height: "0.4",
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "bold",
                }}
              >
                {cell.name}
              </Box>
            </Box>
          </Grid>
        ))}
    </Grid>
  );
};

export default Planogram;

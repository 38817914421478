import { Button, Menu, MenuItem } from "@mui/material";
import {
  MaterialReactTable,
  MRT_Cell,
  MRT_ColumnDef,
  MRT_FilterFn,
  MRT_Row,
  MRT_TableInstance,
} from "material-react-table";
import { MRT_Localization_HE } from "material-react-table/locales/he";
import moment, { Moment } from "moment";
import React, { useState } from "react";
import { useReactToPrint } from "react-to-print";
import * as XLSX from "xlsx";
import Iconify from "./iconify";

interface DataTableProps {
  loading?: boolean;
  data: any[];
  columns: MRT_ColumnDef<any>[];
  exportFileName?: string;
  getRowId?: (row: any) => string;
  actions?: (props: {
    cell: MRT_Cell<any, unknown>;
    row: MRT_Row<any>;
    staticRowIndex?: number;
    table: MRT_TableInstance<any>;
  }) => React.ReactNode;
}

const DataTable: React.FC<DataTableProps> = ({
  loading,
  data,
  columns,
  getRowId,
  actions,
  exportFileName = "table_export",
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const contentRef = React.useRef(null);
  const reactToPrintFn = useReactToPrint({ contentRef });

  const handleExport = (table: MRT_TableInstance<any>) => {
    const headers = columns.map((column) => column.header);
    const rows = table.getFilteredRowModel().rows;
    const excelData = [
      headers,
      ...rows.map((row) => row.getAllCells().filter(cell => cell.column.id !== "mrt-row-actions").map((cell) => cell.renderValue())),
    ];

    const ws = XLSX.utils.aoa_to_sheet(excelData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, `${exportFileName}.xlsx`);
    setAnchorEl(null);
  };

  return (
    <MaterialReactTable
      columns={columns}
      data={data}
      state={{
        isLoading: loading,
        showLoadingOverlay: loading,
      }}
      localization={MRT_Localization_HE}
      enableColumnResizing
      enableColumnOrdering
      columnResizeDirection="rtl"
      enableColumnDragging={false}
      initialState={{
        pagination: { pageSize: 100, pageIndex: 0 },
        density: "compact",
      }}
      getRowId={getRowId}
      paginationDisplayMode="pages"
      muiTableContainerProps={{ ref: contentRef }}
      enableRowActions={Boolean(actions)}
      renderRowActions={actions}
      muiPaginationProps={{
        color: "secondary",
        rowsPerPageOptions: [
          { value: 10, label: "10" },
          { value: 25, label: "25" },
          { value: 50, label: "50" },
          { value: 100, label: "100" },
          { value: 200, label: "200" },
          { value: 500, label: "500" },
          { value: data.length, label: "הכל" },
        ],
        shape: "rounded",
        variant: "outlined",
      }}
      defaultColumn={{
        muiTableBodyCellProps: { align: "left", sx: { fontSize: "small" } },
        muiCopyButtonProps: {
          sx: { fontWeight: 500 },
        },
        size: 100,
      }}
      layoutMode="grid"
      enableColumnPinning
      enableClickToCopy
      enableRowSelection={false}
      enableEditing={false}
      renderTopToolbarCustomActions={({ table }) => (
        <div>
          <Button
            color="primary"
            onClick={(e: any) => setAnchorEl(e.currentTarget)}
            startIcon={<Iconify icon="mdi:export-variant" />}
          >
            ייצוא
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
          >
            <MenuItem
              onClick={() => {
                handleExport(table);
              }}
            >
              קובץ אקסל
            </MenuItem>
            <MenuItem
              onClick={(e) => {
                reactToPrintFn();
                setAnchorEl(null);
              }}
            >
              הדפס
            </MenuItem>
          </Menu>
        </div>
      )}
    />
  );
};

export const filterFnTimeRange: MRT_FilterFn<any> = (row, id, filterValue) => {
  const [startTime, endTime] = filterValue;
  const value: Date = row.getValue(id);
  const h = value.getHours();
  const m = value.getMinutes();
  let starth = 0,
    startm = 0,
    endh = 23,
    endm = 59;
  if (startTime && startTime !== "") {
    starth = startTime.toDate().getHours();
    startm = startTime.toDate().getMinutes();
  }
  if (endTime && endTime !== "") {
    endh = endTime.toDate().getHours();
    endm = endTime.toDate().getMinutes();
  }

  return h >= starth && h <= endh && m >= startm && m <= endm;
};

export const filterFnDateRange: MRT_FilterFn<any> = (row, id, filterValue) => {
  const [startDate, endDate] = filterValue;
  const value: Moment = moment(row.getValue(id));

  const start =
    startDate && startDate.isValid()
      ? startDate.clone().startOf("day")
      : value.startOf("day");

  const end =
    endDate && endDate.isValid()
      ? endDate.clone().endOf("day")
      : value.endOf("day");

  return value.isBetween(start, end, "day", "[]");
};

export const filterFnDateTimeRange: MRT_FilterFn<any> = (
  row,
  id,
  filterValue
) => {
  const [startDate, endDate] = filterValue;
  const value: Moment = moment(row.getValue(id));

  const start =
    startDate && startDate.isValid() ? startDate.clone() : value.startOf("day");

  const end =
    endDate && endDate.isValid() ? endDate.clone() : value.endOf("day");

  return value.isBetween(start, end, null, "[]");
};

export default DataTable;

import { IconButton } from "@mui/material";
import axios from "axios";
import ContianerWapper from "components/ContainerWrapper";
import DataTable from "components/DataTable";
import Iconify from "components/iconify";
import ItemBadge from "components/ItemBadge";
import Planogram, { Cell } from "components/planogram";
import { MRT_ColumnDef } from "material-react-table";
import { useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";

const InventoryLocationView = () => {
  const { locationId } = useParams();
  const navigate = useNavigate();
  const [inventory, setInventory] = useState<Cell[]>([]);
  const [planogram, setPlanogram] = useState<Boolean>(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const response = await axios.get(`/inventory/${locationId}`);
        setInventory(response.data);
      } catch (e: any) {
        if (e.response.status === 403) {
          toast.error("אין לך הרשאה לצפות במלאי של מיקום זה");
          navigate("/");
        }
        console.error(e);
      } finally {
        setLoading(false);
      }
    })();
  }, [locationId, navigate]);

  const { data, columns } = useMemo(
    () => ({
      columns: [
        { accessorKey: "sku", header: "ברקוד" },
        {
          accessorKey: "name",
          header: "שם",
          filterVariant: "autocomplete",
          filterSelectOptions: Array.from(
            new Set(
              inventory
                .filter((cell) => cell.quantity !== 0)
                .map((inv) => inv.name)
            )
          ),
        },
        { accessorKey: "quantity", header: "כמות" },
      ] as MRT_ColumnDef<any, unknown>[],
      data: inventory.filter((cell) => cell.quantity !== 0),
    }),
    [inventory]
  );

  return (
    <ContianerWapper
      title={`מלאי ${locationId}`}
      secondaryAction={
        <IconButton
          onClick={() => setPlanogram(!planogram)}
          size="large"
          color="primary"
        >
          <Iconify icon={planogram ? "mdi:view-list" : "mdi:grid"} />
        </IconButton>
      }
    >
      {planogram && (
        <Planogram
          cells={inventory}
          getBadge={(cell) =>
            cell.quantity !== 0 ? <ItemBadge>{cell.quantity}</ItemBadge> : null
          }
        />
      )}
      {!planogram && (
        <DataTable
          getRowId={(row: any) => row.sku}
          columns={columns}
          data={data}
          exportFileName="תנועות מלאי"
          loading={loading}
        />
      )}
    </ContianerWapper>
  );
};

export default InventoryLocationView;

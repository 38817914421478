import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  SxProps,
} from "@mui/material";
import React from "react";
import Iconify from "./iconify";

interface ModalProps {
  open: boolean; // Indicates whether the modal is open or not
  handleClose: () => void; // Function to handle modal close
  title?: string; // Optional title for the modal
  xBtn?: boolean; // Indicates whether to show the close button or not
  cancelBtn?: boolean; // Indicates whether to show the cancel button or not
  sx?: SxProps; // Additional styles for the modal
  buttons?: JSX.Element[]; // Array of custom buttons to be rendered in the modal
  idProp?: string; // Id for the draggable component
  hideBackdrop?: boolean; // Indicates whether to hide the backdrop or not
  allowInteraction?: boolean; // Indicates whether to allow interaction with the modal or not
  children: React.ReactNode; // Content of the modal
  disableMaxWidth?: boolean; // Indicates whether to disable the max width of the modal
  fullScreen?: boolean; // Indicates whether to show the modal in full screen or not
  TransitionComponent?: any;
}

const MainModal: React.FC<ModalProps> = ({
  open,
  handleClose,
  title,
  cancelBtn,
  sx,
  buttons,
  idProp,
  hideBackdrop,
  allowInteraction,
  xBtn,
  disableMaxWidth,
  TransitionComponent,
  fullScreen,
  children,
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen={fullScreen}
      PaperProps={{
        elevation: 0,
        sx: { minWidth: 300, minHeight: 400, ...sx },
        style: allowInteraction ? { pointerEvents: "auto" } : undefined,
      }}
      hideBackdrop={hideBackdrop}
      disableEnforceFocus={allowInteraction}
      maxWidth={disableMaxWidth ? false : undefined}
      TransitionComponent={TransitionComponent}
      style={allowInteraction ? { pointerEvents: "none" } : undefined}
    >
      {title && (
        <DialogTitle variant="h5" id={idProp}>
          {title}
        </DialogTitle>
      )}{" "}
      {/* Render the title if provided */}
      {xBtn && (
        <IconButton
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Iconify icon="eva:close-outline" />
        </IconButton>
      )}
      {title && <Divider />} {/* Render a divider if title is provided */}
      <DialogContent sx={{ height: 1 }}>
        {children} {/* Render the content of the modal */}
      </DialogContent>
      <DialogActions>
        {cancelBtn && <Button onClick={handleClose}>ביטול</Button>}{" "}
        {/* Render the cancel button if cancelBtn is true */}
        {buttons &&
          buttons.map((btn, index) => (
            <React.Fragment key={index}>{btn}</React.Fragment>
          ))}
      </DialogActions>
    </Dialog>
  );
};

export default MainModal;

import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { AuthInit, AuthProvider, setupAxios } from "auth";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import axios from "axios";
import moment from "moment-timezone";
import "moment/locale/he";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

setupAxios(axios);

moment.tz.setDefault("Asia/Jerusalem");
moment.locale("he");

root.render(
  <HelmetProvider>
    <BrowserRouter>
      <AuthProvider>
        <AuthInit>
          <App />
        </AuthInit>
      </AuthProvider>
    </BrowserRouter>
  </HelmetProvider>
);

reportWebVitals();

import { forwardRef } from "react";

import Box from "@mui/material/Box";
import Link from "@mui/material/Link";

import { RouterLink } from "routes/components";

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }: any, ref) => {
  // OR using local (public folder)
  // -------------------------------------------------------
  // const logo = (
  //   <Box
  //     component="img"
  //     src="/logo/logo_single.svg" => your path
  //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
  //   />
  // );

  const logo = (
    <Box
      ref={ref}
      component="div"
      sx={{
        width: 200,
        height: 80,
        display: "inline-flex",
        justifyContent: "center",
        ...sx,
      }}
      {...other}
    >
      <img src="/assets/header-logo.svg" alt="Mini Yellow" />
    </Box>
  );

  if (disabledLink) {
    return logo;
  }

  return (
    <Link component={RouterLink} href="" sx={{ display: "contents" }}>
      {logo}
    </Link>
  );
});

export default Logo;

import {
  Box,
  Link,
  Step,
  StepLabel,
  Stepper,
  Typography
} from "@mui/material";
import { useAuth } from "auth";
import axios from "axios";
import ContianerWapper from "components/ContainerWrapper";
import Iconify from "components/iconify/iconify";
import MachineActions from "components/MachineActions";
import { Cell } from "components/planogram";
import moment from "moment-timezone";
import { useEffect, useLayoutEffect, useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { RouterLink } from "routes/components";
import CapturePhoto from "./CapturePhoto";
import Order from "./Order";
import ReturnOrder from "./returnOrder";
import SelectTruck from "./SelectTruck";

const steps = ["תצלום", "החזרות", "מילוי מקרר", "תצלום סוף", "סיום"];

const MachineView = () => {
  const { id } = useParams();
  const [activeStep, setActiveStep] = useState(0);
  const [log_id, setLogId] = useState<number>();
  const [truck_id, setTruckId] = useState<string>();
  const [cells, setCells] = useState<Cell[]>([]);
  const { currentUser } = useAuth();


  useLayoutEffect(() => {
    const item = localStorage.getItem("machine-process");
    if (item) {
      const { activeStep, log_id, timestamp, machine_id } = JSON.parse(item);
      const diff = moment().diff(moment(timestamp), "minutes");
      if (machine_id === id && diff < 60 && activeStep < steps.length) {
        setActiveStep(activeStep);
        setLogId(log_id);
      } else {
        localStorage.removeItem("machine-process");
      }
    }
    if (currentUser?.role === "admin") {
      setActiveStep(-1);
    } else {
      if (!currentUser?.location_id) {
        window.location.reload();
      }
      setTruckId(currentUser?.location_id);
    }
  }, [currentUser, id]);

  useEffect(() => {
    (async () => {
      try {
        const response = await axios(`/machines/${id}/planogram`);
        setCells(response.data);
      } catch (error) {
        console.error(error);
        toast.error("שגיאה בטעינת המכונה");
      }
    })();
  }, [id]);

  const handleImageStart = async (photo: string) => {
    return new Promise<void>((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            const { latitude, longitude } = position.coords;
            try {
              const response = await axios.post(`machines/${id}/photo-start`, {
                photo,
                truck_id,
                latitude,
                longitude,
              });
              const { log_id } = response.data;
              const item = {
                log_id,
                activeStep: activeStep + 1,
                timestamp: moment(),
                machine_id: id,
              };
              localStorage.setItem("machine-process", JSON.stringify(item));
              setLogId(log_id);
              setActiveStep((prev) => prev + 1);
              resolve(); // Resolve the promise when done
            } catch {
              toast.error("שגיאה בשמירת התמונה");
              reject(new Error("Error saving image"));
            }
          },
          (error) => {
            console.error(error);
            toast.error("שגיאה בקבלת מיקום אנא בדוק כי קיימת קליטה והרשאת מיקום");
            reject(new Error("Error getting location"));
          }
        );
      } else {
        toast.error("הדפדפן שלך לא תומך בקבלת מיקום");
        reject(new Error("Geolocation not supported"));
      }
    });
  };

  const handleImageEnd = async (photo: string) => {
    try {
      await axios.post(`machines/${id}/photo-end`, { photo, log_id });
      setActiveStep((prev) => prev + 1);
      localStorage.removeItem("machine-process");
    } catch {
      toast.error("שגיאה בשמירת התמונה")
    }
  }

  const handleTruckSubmit = (data: { truck: string }) => {
    setTruckId(data.truck);
    setActiveStep(0);
  };

  return (
    <ContianerWapper
      title={`מכונה ${id}`}
      secondaryAction={
        id && <MachineActions locationId={id} />
      }
    >
      <Box sx={{ width: "100%", p: 1 }}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      {activeStep === -1 && <SelectTruck onSubmit={handleTruckSubmit} />}
      {activeStep === 0 && (
        <CapturePhoto
          handlePhoto={handleImageStart}
          nextStep={() => setActiveStep((prev) => prev + 1)}
        />
      )}
      {log_id && activeStep === 1 && (
        <ReturnOrder
          cells={cells}
          nextStep={() => setActiveStep((prev) => prev + 1)}
          log_id={log_id}
        />
      )}
      {log_id && activeStep === 2 && (
        <Order
          cells={cells}
          nextStep={() => setActiveStep((prev) => prev + 1)}
          log_id={log_id}
        />
      )}
      {activeStep === 3 && (
        <CapturePhoto
          handlePhoto={handleImageEnd}
          nextStep={() => setActiveStep((prev) => prev + 1)}
        />
      )}
      {activeStep === 4 && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            m: 5,
            p: 5,
          }}
        >
          <Typography variant="h5">{`מקרר ${id} הושלם בהצלחה`}</Typography>
          <Iconify
            icon="icon-park-twotone:success"
            sx={{ height: 200, width: 200, color: "success.main", my: 3 }}
          />
          <Link component={RouterLink} to="/" sx={{ fontSize: "large" }}>
            חזרה לדף בית
          </Link>
        </Box>
      )}

    </ContianerWapper>
  );
};

export default MachineView;

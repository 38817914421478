import { Box } from "@mui/material";
import axios from "axios";
import { SelectInput } from "components/Inputs";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import toast from "react-hot-toast";

const SelectClient = () => {
  const { control } = useFormContext();
  const [clients, setClients] = useState<Record<string, string>>({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const response = await axios.get("/locations/clients");
        const temp: Record<string, string> = {};
        for (const client of response.data) {
          temp[client.code] = client.name;
        }
        setClients(temp);
      } catch (e) {
        console.log(e);
        toast.error("שגיאה בטעינת הלקוחות");
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <Box p={3}>
      <SelectInput
        control={control}
        fieldName="client"
        label="בחר לקוח"
        options={Object.keys(clients)}
        getOptionLabel={(c) => `${c} - ${clients[c]}`}
        loading={loading}
        rules={{ required: "שדה חובה" }}
      />
    </Box>
  );
};

export default SelectClient;
